import { useState } from "react"
import HeroImg from "../components/heroImg/HeroImg"
import NavBar from "../components/ui/NavBar/NavBar"
import RestaurantList from "../components/restaurantBtns/RestaurantList"

import './Home.css'

const Pickup = (params) => {
    const [serviceTypeNum, setServiceTypeNum] = useState(0)

    const restaurantServiceTypeParent = (type) => {
        if (type === 'delivery') {
            setServiceTypeNum(0)
        } else {
            setServiceTypeNum(1)
        }
    }

    return <div className="home-view-outter-wrapper">
        <div className="home-div-border"></div>
        <div className="home-view-inner-wrapper">
            <a href="#main" className="skip-to-main">Skip to main content</a>
            <NavBar restaurantServiceTypeParent={restaurantServiceTypeParent} />

            <div className="home-view-wrapper" id="main" aria-label="main-view">
                <HeroImg />
                <RestaurantList serviceTypeChild={serviceTypeNum} id="main" />
            </div>
        </div>

    </div>
}

export default Pickup